import { createServices } from '@vs/vsf-boot';
export default createServices({
  AccountController_updatePasswordToLogin_b2ca8f: {
    method: 'post',
    url: '/api/user-account/update-password',
    parameterFomatter: (data?: {
      btoParamE: UpdateUserBto9D1Edc;
      captcha: string;
    }) => data,
    responseFormatter: (_, __, data?: CreateVo887414) => data,
  },
});
