import { createServices } from '@vs/vsf-boot';
export default createServices({
  BannerInfoController_updateBanner_e49ca4: {
    method: 'post',
    url: '/api/banner-info/update-banner',
    parameterFomatter: (data?: { btoParam: UpdateBannerBtoD06F4E }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  BannerInfoController_getById_70b3fd: {
    method: 'post',
    url: '/api/banner-info/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: BannerInfoVo6013Ec) => data,
  },
});
