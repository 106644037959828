import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';

import './index.less'

const Index = () => {
  return (
    <div className='about'>
      <div className='header-bg'>
        <div className="about-title">
          <div className="about-title1">ABOUT US</div>
          <div className="about-title2">关于我们</div>
        </div>
      </div>
      <div className='box1'>
        <div className="about-us">
          <div className="title-box">
            <span>平台介绍</span>
            <div></div>
          </div>
        </div>
        <p className='info main-box'>AI for 新生儿遗传代谢病人工智能辅助诊断平台是一款专为新生儿遗传代谢病诊断而设计的高科技产品，旨在通过人工智能技术，为医生提供快速、准确的诊断支持，提高新生儿遗传代谢病的诊断效率和准确性。</p>
      </div>

      <div className='box2 main-box'>
        <div className="about-us">
          <div className="title-box">
            <span>我们的团队</span>
            <div></div>
          </div>
        </div>
        <div className='flex-box'>
          <div className='img'></div>
          <div className='img'></div>
          <div className='img'></div>
          <div className='img'></div>
        </div>
        <div className='info main-box'>
          新生儿遗传代谢病人工智能辅助诊断平台是一款专为新生儿遗传代谢病诊断而设计的高科技产品，旨在通过人工智能技术，为医生提供快速、准确的诊断支持，提高新生儿遗传代谢病的诊断效率和准确性。
        </div>
      </div>

      <div className='box1'>
        <div className="about-us">
          <div className="title-box">
            <span>历史与使命</span>
            <div></div>
          </div>
        </div>
        <p className='info main-box'>AI for 新生儿遗传代谢病人工智能辅助诊断平台是一款专为新生儿遗传代谢病诊断而设计的高科技产品，旨在通过人工智能技术，为医生提供快速、准确的诊断支持，提高新生儿遗传代谢病的诊断效率和准确性。</p>
      </div>

      <div className='about-content main-box'>
        <div className='box-l'>
          <span className='item-title'>联系我们</span>
          <p className='label'>邮箱</p>
          <p>Market@afh.com</p>
          <p className='label'>电话</p>
          <p>(+86) 571-8502-2088</p>
          <p className='label'>传真</p>
          <p>(+86) 571-8981-5505</p>
          <p className='label'>公司地址</p>
          <p className='dizhi'>中国浙江省杭州市滨江区网商路699号 (310052)</p>
        </div>
        <div className='line'></div>
        <div className='box-r'>
          <div className='postion'>

          </div>
          <div className='img'>

          </div>
        </div>
      </div>
    </div>
  );
};

export default definePage(Index);
