import { RouteConfig } from '@vs/vsf-boot';

import Root from '@/layouts';
import Login from '@/module/login';
import Index from '@/pages';
import FeedbackHistory from '@/pages/FeedbackHistory';

// import Sign from '@/pages/Sign';
import pageRoutes from './routes';

const routes: RouteConfig[] = [
  {
    path: '/login',
    component: Login,
  },
  // {
  //   path: '/sign',
  //   component: Sign,
  // },
  {
    path: '/feedback/history',
    exact: true,
    component: FeedbackHistory,
    key: 'FeedbackHistory',
  },
  {
    path: '/',
    component: Root,
    redirect: '/index',
    routes: [
      {
        path: '/index',
        exact: true,
        component: Index,
        key: 'index',
      },
      ...pageRoutes,
    ],
  },
];

export default routes;
