import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Modal,
  Breadcrumb
} from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';
import './index.less'
import img1 from '@/images/Slice 1@2x.png';
import img2 from '@/images/Slice 2@2x.png';
import img3 from '@/images/Slice 3@2x.png';

const CreateProject = (props) => {
  console.log(vsf.services);
  const { global } = props?.stores || {};
  const {
    directoryList,
    getDirectoryList,
    currentDirectory,
    setCurrentDirectory,
  } = global || {};
  console.log(currentDirectory);
  const [directory, setDirectory] = useState({});
  const onChange = (value, selectedOptions) => {
    console.log(value, selectedOptions);
  };

  const reqDirectoryDetail = async (params) => {
    // Tips: 函数入参包含通过表单params属性传入的额外参数(平铺)
    // Tips: 以下是你选择的请求函数
    const res = await vsf.services?.ProjectInfoController_getById_d1b300?.(
      {
        // TODO: 请检查以下这部分传参是否符合需求
        id: props.routes?.query.directoryId,
      },
    ); // Tips: 需要返回内容数据，结构和表单RO一致
    setDirectory(res.data || {});
    return res?.data;
  }

  useEffect(() => {
    reqDirectoryDetail();
  }, [])
  useEffect(() => {
    if(directoryList.length == 0) getDirectoryList();
}, [getDirectoryList]);
useEffect(()=> {
  if(directoryList.length > 0 && directory.id ) {
    if(!currentDirectory.id || !currentDirectory.categorySecondInfoDtoList.find(item=> item.id == directory.categorySecondInfoId)) {
      console.log(currentDirectory, directory.categorySecondInfoId, directoryList);
      setCurrentDirectory(directoryList.find(item => item.categorySecondInfoDtoList.find(el => el.id == directory.categorySecondInfoId)))
    }
  }
}, [directoryList, directory])

  return (
    <div className='directory-detail'>
      <div className='header-bg'></div>
      <div className='main-box'>
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item><Button type="text" onClick={()=> vsf.navigateTo('/index')}>首页</Button></Breadcrumb.Item>
          <Breadcrumb.Item>
            <Button type="text" onClick={()=> vsf.navigateTo(`/directory?fistId=${currentDirectory.id}`)} >{currentDirectory.firstDirectory}</Button>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Button type="text" onClick={()=> vsf.navigateTo(`/directory?fistId=${currentDirectory.id}&secondId=${directory.categorySecondInfoId}`)} > {currentDirectory?.categorySecondInfoDtoList?.find(item => item.id === directory.categorySecondInfoId)?.secondDirectory || ''}</Button>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {directory.projectName}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className='content-box'>
          <div className='detail-header'>
            <div className='icon-box'></div>
            <div className='header-right'>
              <div className='title'>{directory.projectName}</div>
              <div className='info'>
                <div className='info1'><span className='label'>分类：</span>{currentDirectory?.firstDirectory || ''}-{currentDirectory?.categorySecondInfoDtoList?.find(item => item.id === directory.categorySecondInfoId)?.secondDirectory || ''}</div>
                <div className='info2'><span className='label'>申报单位：</span>{directory.declarationUnit}</div>
              </div>
            </div>
          </div>
          <div className='line'></div>
          <div className='content-item'>
            <div className='item-title'><img style={{width: 24, height: 24}} src={img1} alt="" />基础介绍</div>
            <div className='item-text'>
              <p>{directory.baseIntroduction}</p>
            </div>
          </div>
          <div className='content-item'>
            <div className='item-title'><img style={{width: 24, height: 24}} src={img2} alt="" />视频介绍</div>
            <div className='item-text'>
              <video
                controls="controls"
                crossOrigin="Anonymous"
                className='item-left'
                src={directory.videoIntroduction}
                width="250">
              </video>
            </div>
          </div>
          <div className='content-item'>
            <div className='item-title'><img style={{width: 24, height: 24}} src={img1} alt="" />核心技术</div>
            <div className='item-text'>
              <p>{directory.coreTechnology}</p>
            </div>
          </div>
          <div className='content-item'>
            <div className='item-title'><img style={{width: 24, height: 24}} src={img3} alt="" />详细内容</div>
            <div className='item-details' dangerouslySetInnerHTML={{ __html: directory.details}}>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default definePage(CreateProject);
