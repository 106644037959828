import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserController_getPagedByUserPageQto_f6c9c6: {
    method: 'post',
    url: '/api/user-account-entrance-web/get-paged-by-user-page-qto',
    parameterFomatter: (data?: { qto: UserPageQtoC9F06D }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: IdentificationPageVo887414[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
