import { createServices } from '@vs/vsf-boot';
export default createServices({
  CategoryController_deleteSecondCategory_a2bf29: {
    method: 'post',
    url: '/api/category-info/delete-second-category',
    parameterFomatter: (data?: { btoParam: DeleteSecondCategoryBto9D9F0C }) =>
      data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
