import { createServices } from '@vs/vsf-boot';
export default createServices({
  ProjectInfoController_create_942887: {
    method: 'post',
    url: '/api/project-info/create',
    parameterFomatter: (data?: { btoParam: CreateBto36Ded1 }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ProjectInfoController_getByProjectId_87887b: {
    method: 'post',
    url: '/api/project-info/get-by-project-id',
    parameterFomatter: (data?: { projectId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
