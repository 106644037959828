import vsf, { definePage } from '@vs/vsf-boot';
import { Layout, Page, Section, List, Image } from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';
import { Carousel, Button } from 'antd';
import { RightOutlined, PlusOutlined } from '@ant-design/icons';
import Slice7 from '@/images/Slice7.png';
import Icon from '@/module/Icon';

import './index.less';

const Index = (props) => {
  const { global } = props?.stores || {};
  const {
    directoryList,
    getDirectoryList,
    currentDirectory,
    setDirectoryList,
    setCurrentDirectory,
  } = global || {};
  const [bannerList, setBanners] = useState([]);

  const reqBanners = async (params) => {
    //
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.BannerInfoController_getAllByBannerStatusQto_57a838?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            ...(params ?? {}),
          },
        },
      ); // TODO: 你可能需要整理返回数据格式
    setBanners(res?.data?.sort((a, b) => a.rotationOrder - b.rotationOrder));
  };

  useEffect(() => {
    getDirectoryList();
  }, [getDirectoryList]);

  useEffect(() => {
    reqBanners();
  }, []);
  return (
    <div className="index">
      <div className='swiper_bg'>
        <Carousel style={{ width: '100%' }} className="my-swiper" autoplay>
          {bannerList?.map((banner) => (
            <div key={banner.id}>
              <div
                className="my-swiper-content"
                style={{ backgroundImage: `'url(${banner.imgUrl})'` }}
              >
                {/* <div className='banner-title'>{banner.title}</div> */}
                {banner.bannerUrl ? (
                  <a target="_blank" href={banner.bannerUrl}>
                    <img src={banner.imgUrl} alt="" />
                  </a>
                ) : (
                  <img src={banner.imgUrl} alt="" />
                )}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <div className="about-us">
        {/* <div className="title-box">
          <span>关于我们</span>
          <div></div>
        </div> */}
        <img className="left" src={Slice7}></img>
        <p className="info main-box">
          AI for
          Health是一个致力于推动人工智能在医疗领域的应用的平台。该平台提供了多种方式，这个平台主要是用于展示与医疗领域相关的人工智能项目，供医院相关部门进行查看和评估。它可能包括各种类型的AI应用，如图像识别、自然语言处理、数据分析等，旨在帮助医院了解和应用最新的人工智能技术。该平台可能提供项目的详细信息，包括项目的背景介绍、目标和应用场景，以及相关的技术和算法。此外，还可能提供项目的演示视频或实际案例，以便医院相关部门更直观地了解AI在医疗领域的应用效果。通过这个平台，医院可以了解到不同AI项目的优势和适用性，从而选择适合自己需求和资源的项目进行合作或引入。同时，该平台还可以促进医院之间的交流和合作，推动医疗领域的创新和发展。
        </p>
        <img className="right" src={Slice7}></img>
      </div>
      <div className="dir-box">
        {/* <div className="title-box">
          <span>目录管理</span>
          <div></div>
        </div> */}

        <div className="main-box list">
          <List
            grid={{
              gutter: 24,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 2,
              xl: 3,
              xxl: 3,
            }}
            dataSource={directoryList}
            renderItem={(item, index) => (
              <List.Item key={item.id}>
                <div key={item.id} className="list-item">
                  <div
                    onClick={() =>
                      vsf.navigateTo(`/directory?fistId=${item.id}`)
                    }
                    className="item-left"
                    style={{ backgroundImage: `url(${item.imgUrl})` }}
                  ></div>
                  <div
                    className="moke"
                    onClick={() =>
                      vsf.navigateTo(`/directory?fistId=${item.id}`)
                    }
                  >
                    <div className="card-first">{item.firstDirectory}</div>
                    <div className="card-content">{item.baseIntroduction}</div>
                  </div>
                  <div className="card-second">
                    <div className="second_title">{item.firstDirectory}</div>
                    <div className="second_info">{item.baseIntroduction}</div>
                    {item.categorySecondInfoDtoList?.length > 3 ? (
                      <>
                        {item.categorySecondInfoDtoList
                          ?.slice(0, 2)
                          ?.map((el) => (
                            <div
                              onClick={() =>
                                vsf.navigateTo(
                                  `/directory?fistId=${item.id}&secondId=${el.id}`,
                                )
                              }
                              key={el.id}
                              className="card-second-item"
                            >
                              <span>{el.secondDirectory}</span>
                              <span>
                                <RightOutlined />
                              </span>
                            </div>
                          ))}
                        <div
                          onClick={() =>
                            vsf.navigateTo(`/directory?fistId=${item.id}`)
                          }
                          className="card-second-item"
                        >
                          <span>更多</span>
                          <span>
                            <RightOutlined />
                          </span>
                        </div>
                      </>
                    ) : (
                      item.categorySecondInfoDtoList?.map((el) => (
                        <div
                          onClick={() =>
                            vsf.navigateTo(
                              `/directory?fistId=${item.id}&secondId=${el.id}`,
                            )
                          }
                          key={el.id}
                          className="card-second-item"
                        >
                          <span>{el.secondDirectory}</span>
                          <span>
                            <RightOutlined />
                          </span>
                        </div>
                      ))
                    )}
                    <Button
                      onClick={() =>
                        vsf.navigateTo(`/directory?fistId=${item.id}`)
                      }
                      className="more_btn"
                      type="link"
                    >
                      查看更多
                      <Icon type="icon-you" size={16} />
                    </Button>
                  </div>
                  <Button
                    onClick={() =>
                      vsf.navigateTo(`/directory?fistId=${item.id}`)
                    }
                    className="more_btn1"
                    type="link"
                  >
                    查看更多
                    <Icon type="icon-you" size={16} />
                  </Button>
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default definePage(Index);
