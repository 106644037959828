import { createServices } from '@vs/vsf-boot';
export default createServices({
  ProjectInfoController_getById_d1b300: {
    method: 'post',
    url: '/api/project-info/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: ProductInfoVo12Db7B) => data,
  },
  ProjectInfoController_updateProject_edcbef: {
    method: 'post',
    url: '/api/project-info/update-project',
    parameterFomatter: (data?: { btoParam: UpdateProjectBto36Ded1 }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
