import { createServices } from '@vs/vsf-boot';
export default createServices({
  AdminController_getPagedByAdminPageQto_f8f0a6: {
    method: 'post',
    url: '/api/user-account/get-paged-by-admin-page-qto',
    parameterFomatter: (data?: { qto: AdminPageQtoC9F06D }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: IdentificationPageVo887414[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
