import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserAccountController_updateUser_f93b96: {
    method: 'post',
    url: '/api/user-account/update-user',
    parameterFomatter: (data?: {
      btoParam: UpdateUserBto9D1Edc;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: AccountDetailVo887414) => data,
  },
  UserPageController_getById_97f416: {
    method: 'post',
    url: '/api/user-account/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: AccountDetailVo887414) => data,
  },
});
