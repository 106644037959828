import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserAccountController_deleteUser_5a0746: {
    method: 'post',
    url: '/api/user-account/delete-user',
    parameterFomatter: (data?: {
      btoParam: DeleteUserBto9D1Edc;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});
