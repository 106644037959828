import { createServices } from '@vs/vsf-boot';
export default createServices({
  SuggestionController_getById_abcb51: {
    method: 'post',
    url: '/api/suggestion-info/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: SuggestionPageVo0E1C65) => data,
  },
  SuggestionController_getBySuggestionUserId_f6253a: {
    method: 'post',
    url: '/api/suggestion-info/get-by-suggestion-user-id',
    parameterFomatter: (data?: { suggestionUserId: number }) => data,
    responseFormatter: (_, __, data?: SuggestionAllVo0E1C65[]) => data,
  },
});
