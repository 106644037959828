import { createServices } from '@vs/vsf-boot';
export default createServices({
  CategoryController_createFirstCategory_6f3a2c: {
    method: 'post',
    url: '/api/category-info/create-first-category',
    parameterFomatter: (data?: { btoParam: CreateFirstCategoryBto9D9F0C }) =>
      data,
    responseFormatter: (_, __, data?: number) => data,
  },
  CategoryController_getById_7d4b2f: {
    method: 'post',
    url: '/api/category-info/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: CategoryFirstVoA8B300) => data,
  },
});
