import './index.less';

import { renderRoutes, RouteConfig } from '@vs/vsf-boot';
import { Layout } from '@vs/vsf-kit';
import React, { useState } from 'react';

import Login from '@/module/login';
import Sign from '@/module/Sign';

import Header from './header';
const { Sider } = Layout;

type ContainerProps = {
  route: RouteConfig;
  location: Location;
};
const Container: React.FC<ContainerProps> = (props: any) => {
  const { route } = props;
  const [collapsed, setCollapsed] = useState(false);
  const { global } = props?.stores || {};
  const { loginModalOpen, signModalOpen } = global || {};

  return (
    <div className="vsf-layout">
      {/* <div className="vsf-layout-header">顶部信息: ai-healthcare</div> */}
      <Header />
      <div className="vsf-layout-content">
        {/* <Sider
          className="vsf-layout-sider"
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div>侧边信息</div>
        </Sider> */}
        <div className="vsf-layout-main">
          {renderRoutes(route.routes, route.redirect)}
        </div>
      </div>
      {loginModalOpen && <Login />}
      {signModalOpen && <Sign />}
    </div>
  );
};

export default Container;
