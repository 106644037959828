import { createServices } from '@vs/vsf-boot';
export default createServices({
  CategoryController_createSecondCategory_c290c0: {
    method: 'post',
    url: '/api/category-info/create-second-category',
    parameterFomatter: (data?: { btoParam: CreateSecondCategoryBto9D9F0C }) =>
      data,
    responseFormatter: (_, __, data?: number) => data,
  },
  SecondCategoryController_getBySecondCategoryId_856c1e: {
    method: 'post',
    url: '/api/category-info/get-by-second-category-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: SecondCategoryVoA8B300) => data,
  },
});
