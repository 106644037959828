import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSFormFooter,
  VSFormFooterSubmit,
  VSForm,
  VSFormLayout,
  Button,
} from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';
import './index.less';

const CreateSuggestion = (props) => {
  const { global } = props?.stores || {};
  const { userData, userInfo, setLoginModalOpen } = global || {};

  const toHistory = () => {
    if (!userInfo?.userId) {
      setLoginModalOpen(true);
      return vsf.showToast('登录后可查看');
    }
    vsf.navigateTo('/feedback/history');
  };

  useEffect(() => {
    if (!userInfo?.userId) {
      setLoginModalOpen(true);
      return vsf.showToast('登录后才能进行意见反馈');
    }
  }, []);

  return (
    <div className="feedback">
      <div className="header-bg">
        <div className="about-title">
          <div className="about-title1">Feedback</div>
          <div className="about-title2">意见反馈</div>
        </div>
      </div>
      <div className="main-box feedback-content">
        <div className="form">
          <VSForm
            vsid="38396"
            labelCol={{ span: 5 }}
            // wrapperCol={{ span: 10 }}
            onSubmit={async (params) => {
              // Tips: 函数入参为表单数据，格式和RO一致
              // Tips: 以下是你选择的请求函数
              if (!userInfo?.userId) return vsf.showToast('意见反馈须登录');
              const res =
                await vsf.services?.SuggestionController_createSuggestion_ebd855?.(
                  {
                    // TODO: 请检查以下这部分传参是否符合需求
                    btoParam: { ...params, suggestionUserId: userInfo?.userId },
                  },
                ); // Tips: 需要返回提交的完整数据，和表单RO一致
              if (res.code == 200) {
                vsf.showToast('提交成功');
                reqFeedBackList();
              }
              return params;
            }}
          >
            <VSFormLayout key="0" columnCount={1}>
              <VSFormItem
                name={['suggestionContent']}
                label="反馈的内容"
                valueType="textarea"
                rules={[
                  { required: true },
                  {
                    message: '反馈的内容长度不合法',
                    type: 'string',
                    min: 0,
                    max: 300,
                  },
                ]}
                fieldProps={{
                  showCount: true,
                  maxLength: 300,
                  rows: 6,
                }}
              />

              <VSFormItem
                name={['suggestionType']}
                label="意见的类型"
                valueType="select"
                dataSource={[
                  { label: '功能建议', value: 'FUNCTION_SUGGESTION' },
                  { label: '服务投诉', value: 'SERVICE_COMPLAINTS' },
                  { label: '功能异常', value: 'DYSFUNCTION' },
                  { label: '其他反馈', value: 'OTHER' },
                ]}
                rules={[{ required: true }]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['suggestionPhone']}
                label="反馈人手机号"
                valueType="text"
                rules={[
                  { required: true },
                  {
                    pattern:
                      /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/,
                    message: '请输入正确的手机号',
                  },
                ]}
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormFooter>
              <VSFormFooterSubmit
                style={{ width: 180, marginLeft: 300 }}
                children="提交"
              />
            </VSFormFooter>
            <Button
              type="link"
              onClick={toHistory}
              className="feedback-history"
            >
              历史记录
            </Button>
          </VSForm>
        </div>
      </div>
    </div>
  );
};
export default definePage(CreateSuggestion);
