import { createServices } from '@vs/vsf-boot';
export default createServices({
  CategoryController_updateFirstCategory_0122fe: {
    method: 'post',
    url: '/api/category-info/update-first-category',
    parameterFomatter: (data?: { btoParams: UpdateFirstCategoryBto9D9F0C[] }) =>
      data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  CategoryController_getById_7d4b2f: {
    method: 'post',
    url: '/api/category-info/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: CategoryFirstVoA8B300) => data,
  },
});
