import vsf, { defineStore } from '@vs/vsf-boot';

import storage from '@/utils/storage';

const global = defineStore({
  directoryList: storage.get('directoryList') || [],
  currentDirectory: storage.get('currentDirectory') || {},
  loginModalOpen: false,
  signModalOpen: false,
  userCenter: false,
  forgotPassword: false,
  userData: storage.get('userData') || {},
  userInfo: storage.get('userInfo') || {},
  getDirectoryList: async () => {
    const res =
      await vsf.services?.CategoryController_getAllByCategoryBaseAllQto_7e1022?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {},
        },
      ); // TODO: 你可能需要整理返回数据格式
    global.setDirectoryList(
      res?.data?.filter((item) => item.categorySecondInfoDtoList.length > 0),
    );
    return res?.data?.filter(
      (item) => item.categorySecondInfoDtoList.length > 0,
    );
  },
  // 更新
  setDirectoryList: (v) => {
    global.directoryList = v;
    storage.set('directoryList', v);
  },
  setCurrentDirectory: (v) => {
    global.currentDirectory = v;
    storage.set('currentDirectory', v);
  },
  setLoginModalOpen: (v) => {
    global.loginModalOpen = v;
  },
  setSignModalOpen: (v) => {
    global.signModalOpen = v;
  },
  setForgotPassword: (v) => {
    global.forgotPassword = v;
  },
  setUserData: (v) => {
    global.userData = v;
    storage.set('userData', v);
  },
  setUserInfo: (v) => {
    global.userInfo = v;
    storage.set('userInfo', v);
  },
  setUserCenter: (v) => {
    global.userCenter = v;
  },
});

export default global;
