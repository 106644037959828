import { createServices } from '@vs/vsf-boot';
export default createServices({
  ProjectInfoController_getPagedByProjectInfoPageQto_4cab40: {
    method: 'post',
    url: '/api/project-info/get-paged-by-project-info-page-qto',
    parameterFomatter: (data?: { qto: ProjectInfoPageQto8C9313 }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ProjectInfoPageVo12Db7B[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ProjectInfoController_createProject_ab8df8: {
    method: 'post',
    url: '/api/project-info/create-project',
    parameterFomatter: (data?: { btoParam: CreateProjectBto36Ded1 }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ProjectInfoController_deleteProject_02d767: {
    method: 'post',
    url: '/api/project-info/delete-project',
    parameterFomatter: (data?: { ids: number[] }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ProjectInfoController_updateProject_edcbef: {
    method: 'post',
    url: '/api/project-info/update-project',
    parameterFomatter: (data?: { btoParam: UpdateProjectBto36Ded1 }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  CategoryController_getAllByCategoryAllQto_fdcb23: {
    method: 'post',
    url: '/api/category-info/get-all-by-category-all-qto',
    parameterFomatter: (data?: { qto: CategoryAllQto41Fbfb }) => data,
    responseFormatter: (_, __, data?: CategoryFirstVoA8B300[]) => data,
  },
});
