import { createServices } from '@vs/vsf-boot';
export default createServices({
  AccountController_staffLogin_ed24bb: {
    method: 'post',
    url: '/api/aspirin-user-account-entrance-web/staff-login',
    parameterFomatter: (data?: {
      username: string;
      password: string;
      captcha: string;
      request: HttpServletRequest;
    }) => data,
    responseFormatter: (_, __, data?: CreateVo887414) => data,
  },
});
