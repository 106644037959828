import { createServices } from '@vs/vsf-boot';
export default createServices({
  BannerInfoController_createBanner_e2ed3f: {
    method: 'post',
    url: '/api/banner-info/create-banner',
    parameterFomatter: (data?: { btoParam: CreateBannerBtoD06F4E }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  BannerInfoController_getById_70b3fd: {
    method: 'post',
    url: '/api/banner-info/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: BannerInfoVo6013Ec) => data,
  },
});
