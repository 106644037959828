import { createServices } from '@vs/vsf-boot';
export default createServices({
  AccountController_updateUserFront_366247: {
    method: 'post',
    url: '/api/user-account/update-user-front',
    parameterFomatter: (data?: {
      btoParam: UpdateUserBto9D1Edc;
      captcha: string;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
