import vsf, { definePage } from '@vs/vsf-boot';
import {
  Tabs,
  Typography,
  List,
  Button,
  Modal,
  Spin,
  Tooltip,
} from '@vs/vsf-kit';
import React, { useEffect, MouseEventHandler, useState } from 'react';
import './index.less';
import imgSrc from '@/images/Slice 4@2x.png';
import Slice10 from '@/images/Slice10.png';
import Icon from '@/module/Icon';
import { PlaySquareOutlined } from '@ant-design/icons';
const TouPiao = (props) => {
  const [num, setNum] = useState(0);
  const reqNnm = async () => {
    const res =
      await vsf.services?.ProjectInfoController_getByProjectId_87887b?.({
        projectId: props.projectId,
      }); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    setNum(res.data);
  };
  const handleVote = () => {
    if (!props.userInfo?.userId) {
      props.setLoginModalOpen?.(true);
      return vsf.showToast('登陆后才能投票');
    }
    Modal.confirm({
      title: '投票',
      icon: false,
      content:
        '您是否确认为当前方案投票？投票为实名制，对每个项目只能投票一次，投票后无法更改！',
      okText: '确定',
      cancelText: '取消',
      centered: true,
      onOk: async () => {
        const res = await vsf.services?.ProjectInfoController_create_942887?.({
          // TODO: 请检查以下这部分传参是否符合需求
          btoParam: {
            userId: props.userInfo?.userId,
            projectId: props.projectId,
          },
        });
        if (res.code === 200) {
          vsf.showToast('投票成功');
          setNum(res.data);
        }
      },
      onCancel() {},
      width: 320,
    });
    console.log(props);
  };
  useEffect(() => {
    reqNnm();
  }, []);
  return (
    <Tooltip title="点击投票">
      <div onClick={handleVote} className="num">
        <img style={{ width: 24, height: 24 }} src={imgSrc} alt="" />
        {num}
      </div>
    </Tooltip>
  );
};

const BannerInfo = (props) => {
  const { global } = props?.stores || {};
  const {
    directoryList,
    getDirectoryList,
    currentDirectory,
    setCurrentDirectory,
    setLoginModalOpen,
    userInfo,
  } = global || {};

  const [activeKey, setActiveKey] = useState();
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    getDirectoryList();
  }, [getDirectoryList]);

  useEffect(() => {
    if (Array.isArray(directoryList) && directoryList.length > 0) {
      const firstDirectoryObj =
        directoryList.find((item) => item.id == props.routes?.query.fistId) ||
        directoryList[0] ||
        {};
      if (currentDirectory.id != firstDirectoryObj.id)
        setCurrentDirectory(firstDirectoryObj);
      const activeKey =
        firstDirectoryObj?.categorySecondInfoDtoList?.find(
          (item) => item.id == props.routes?.query.secondId,
        )?.id || firstDirectoryObj.categorySecondInfoDtoList[0].id;
      setActiveKey(activeKey);
      reqProjects({ categorySecondInfoIdIs: activeKey });
    }
  }, [directoryList]);

  useEffect(() => {
    if (activeKey && currentDirectory?.id != props.routes?.query.fistId) {
      console.log(
        'currentDirectory',
        currentDirectory,
        activeKey,
        props.routes?.query.fistId,
      );
      setActiveKey(currentDirectory?.categorySecondInfoDtoList?.[0]?.id);
      reqProjects({
        categorySecondInfoIdIs:
          currentDirectory.categorySecondInfoDtoList[0]?.id,
      });
      vsf.history.replace(
        `/directory?fistId=${currentDirectory.id}&secondId=${currentDirectory.categorySecondInfoDtoList[0]?.id}`,
      );
    }
  }, [currentDirectory]);

  const reqProjects = async (params) => {
    // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
    // Tips: 以下是你选择的请求函数
    setLoading(true);
    const res =
      await vsf.services?.ProjectInfoController_getPagedByStatus_360fe1?.({
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          from: 0,
          size: 20,
          extra: {},
          orderList: [],
          ...(params ?? {}),
        },
      }); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    setLoading(false);
    console.log(res.data.result);
    setProjectList(res?.data?.result ?? res?.data ?? []);
  };

  const onTabClick = (key) => {
    console.log(key);
    vsf.history.replace(
      `/directory?fistId=${currentDirectory.id}&secondId=${key}`,
    );
    setActiveKey(key);
    reqProjects({ categorySecondInfoIdIs: key });
  };
  const todetail = (id) => () => {
    if (!userInfo?.userId) {
      setLoginModalOpen(true);
      return vsf.showToast('登录后才能查看详细内容');
    }
    vsf.navigateTo(`/directory/detail?directoryId=${id}`);
  };

  return (
    <div className="directory">
      <div className="header-bg"></div>
      <div className="card-container main-box">
        <Tabs
          activeKey={activeKey}
          onTabClick={onTabClick}
          // type="card"
          items={currentDirectory?.categorySecondInfoDtoList?.map((item) => {
            return {
              label: item.secondDirectory,
              key: item.id,
              children: loading ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Spin />
                </div>
              ) : (
                <List
                  grid={{
                    gutter: 24,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                    xxl: 1,
                  }}
                  dataSource={projectList}
                  renderItem={(item, index) => (
                    <List.Item key={item.id}>
                      <div key={item.id} className="list-item">
                        <div className="title">
                          {item.projectName}
                          {item.award ? (
                            <span className="award">
                              <img className="award_img" src={Slice10}></img>
                              <span className="award_text">
                                获得奖项：({item.award})
                              </span>
                            </span>
                          ) : null}
                        </div>
                        <div className="item-right">
                          <div className="bg_box">
                            <div className="title1">基础介绍</div>
                            <div className="text">{item.baseIntroduction}</div>
                          </div>
                          {item.videoIntroduction ? (
                            videoId !== item.id ? (
                              <div
                                className="video_box"
                                onClick={() => setVideoId(item.id)}
                              >
                                <PlaySquareOutlined className="video_box_play" />
                              </div>
                            ) : (
                              <video
                                crossOrigin="Anonymous"
                                src={item.videoIntroduction}
                                className="item-left"
                                controls
                                width="240"
                                // muted
                                autoplay="autoplay"
                              ></video>
                            )
                          ) : null}
                          <div className="bg_box">
                            <div className="title1">核心技术</div>
                            <div className="text">{item.coreTechnology}</div>
                          </div>
                        </div>
                        <div className="footer">
                          <TouPiao
                            setLoginModalOpen={setLoginModalOpen}
                            userInfo={userInfo}
                            projectId={item.id}
                          />
                          <Button
                            onClick={todetail(item.id)}
                            className="btn"
                            type="primary"
                          >
                            查看更多
                            <Icon type="icon-you" size={16} />
                          </Button>
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              ),
            };
          })}
        />
      </div>
    </div>
  );
};
export default definePage(BannerInfo);
