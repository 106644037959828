import { createFromIconfontCN } from '@ant-design/icons';
import React from 'react';

import { ICON_URL } from '@/config';

const IconFont = createFromIconfontCN({
  scriptUrl: ICON_URL,
});

type IconType = {
  type: string;
  style?: any;
  size?: number;
  [key: string]: any;
};

const Index = (props: IconType) => {
  const { type, style = {}, size, ...rest } = props;
  const formatStyle = style;
  if (size) {
    formatStyle['fontSize'] = size;
  }
  return (
    <IconFont className="vp-icon" type={type} style={formatStyle} {...rest} />
  );
};

export default Index;
