import vsf, { definePage } from '@vs/vsf-boot';
import { Button } from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';
import './index.less';

const typeMap = {
  FUNCTION_SUGGESTION: '功能建议',
  SERVICE_COMPLAINTS: '服务投诉',
  DYSFUNCTION: '功能异常',
  OTHER: '其他反馈',
};

const CreateSuggestion = (props) => {
  const { global } = props?.stores || {};
  const { userData, userInfo } = global || {};

  const [feedBackList, setFeedBackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const reqFeedBackList = async (params) => {
    setLoading(true);
    const res =
      await vsf.services?.SuggestionController_getBySuggestionUserId_f6253a?.({
        suggestionUserId: userInfo?.userId || '0',
      }); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    setLoading(false);
    setFeedBackList(res.data || []);
  };
  useEffect(() => {
    reqFeedBackList();
  }, []);
  return (
    <div className="feedback_history">
      <div className="main-box feedback-content">
        <Button onClick={() => vsf.goBack()} type="link">
          返回
        </Button>
        <div className="feedback-list">
          <div className="feedback-history">意见反馈历史记录</div>
          {loading ? (
            '加载中...'
          ) : feedBackList.length ? (
            feedBackList?.map((item) => {
              return (
                <div className="feedback-list-item">
                  <div className="feedback-list-item-box">
                    <span>反馈的内容:</span>
                    <div className="feedback-list-item-content">
                      {item.suggestionContent}
                    </div>
                  </div>
                  <div className="feedback-list-item-box">
                    <span>反馈类型:</span>
                    <div className="feedback-list-item-content">
                      {' '}
                      {typeMap[item.suggestionType]}
                    </div>
                  </div>
                  <div className="feedback-list-item-box">
                    <span>登录账号:</span>
                    <div className="feedback-list-item-content">
                      {item.suggestionPhone}
                    </div>
                  </div>
                  <div className="feedback-list-item-box">
                    <span>反馈的时间:</span>
                    <div className="feedback-list-item-content">
                      {' '}
                      {item.createdAt}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="feedback-list-item-content">暂无记录</div>
          )}
        </div>
      </div>
    </div>
  );
};
export default definePage(CreateSuggestion);
