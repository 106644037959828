import Index from '@/pages';
import About from '@/pages/About';
import DirectoryDetail from '@/pages/DirectoryDetail';
import DirectoryPage from '@/pages/DirectoryPage';
import Feedback from '@/pages/Feedback';
const routes = [
  {
    path: '/about',
    exact: true,
    component: About,
    key: 'about',
  },
  {
    path: '/feedback',
    exact: true,
    component: Feedback,
    key: 'Feedback',
  },
  {
    path: '/directory',
    exact: true,
    component: DirectoryPage,
    key: 'DirectoryPage',
  },
  {
    path: '/directory/detail',
    exact: true,
    component: DirectoryDetail,
    key: 'DirectoryPage',
  },
];

export default routes;
