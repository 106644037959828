import { createServices } from '@vs/vsf-boot';
export default createServices({
  SuggestionController_createSuggestion_ebd855: {
    method: 'post',
    url: '/api/suggestion-info/create-suggestion',
    parameterFomatter: (data?: { btoParam: CreateSuggestionBto62505A }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  SuggestionController_getById_abcb51: {
    method: 'post',
    url: '/api/suggestion-info/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: SuggestionPageVo0E1C65) => data,
  },
});
