import { createServices } from '@vs/vsf-boot';
export default createServices({
  ProjectInfoController_getById_d1b300: {
    method: 'post',
    url: '/api/project-info/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: ProductInfoVo12Db7B) => data,
  },
  ProjectInfoController_createProject_ab8df8: {
    method: 'post',
    url: '/api/project-info/create-project',
    parameterFomatter: (data?: { btoParam: CreateProjectBto36Ded1 }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  CategoryController_getAllByCategoryAllQto_fdcb23: {
    method: 'post',
    url: '/api/category-info/get-all-by-category-all-qto',
    parameterFomatter: (data?: { qto: CategoryAllQto41Fbfb }) => data,
    responseFormatter: (_, __, data?: CategoryFirstVoA8B300[]) => data,
  },
  CategoryController_getAllByCategoryBaseAllQto_7e1022: {
    method: 'post',
    url: '/api/category-info/get-all-by-category-base-all-qto',
    parameterFomatter: (data?: { qto: CategoryBaseAllQto41Fbfb }) => data,
    responseFormatter: (_, __, data?: CategoryFirstBaseVoA8B300[]) => data,
  },
  SecondCategoryController_getByCategoryFirstInfoIds_d7db8a: {
    method: 'post',
    url: '/api/category-info/get-by-category-first-info-ids',
    parameterFomatter: (data?: { categoryFirstInfoId: number[] }) => data,
    responseFormatter: (_, __, data?: CategorySecondVoA8B300[]) => data,
  },
  CategoryController_getByFirstIdsToAll_9f271e: {
    method: 'post',
    url: '/api/category-info/get-by-first-ids-to-all',
    parameterFomatter: (data?: { id: number[] }) => data,
    responseFormatter: (_, __, data?: CategoryFirstBaseVoA8B300[]) => data,
  },
});
