import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Checkbox, Form, Input, Tabs, Row, Col } from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';
import services from './services';
import {
  AlipayCircleOutlined,
  TaobaoCircleOutlined,
  WeiboCircleOutlined,
  MailOutlined,
  MobileOutlined,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons';

import './index.less';

const { TabPane } = Tabs;

const Login = (props) => {
  const { global } = props?.stores || {};
  const {
    setLoginModalOpen,
    loginModalOpen,
    setSignModalOpen,
    setForgotPassword,
    setUserCenter,
    setUserInfo,
    setUserData,
  } = global || {};

  const [form] = Form.useForm();
  const [isLogin, setIsLogin] = useState(true);
  const [captcha, setCaptcha] = useState({});
  const onRegister = values => {
    console.log('Received values of form: ', values);
    services
      .register({
        ...values,
        userType: 'PATIENT',
      })
      .then(res => {
        console.log(res);
      });
  };
  const onRegisterCode = values => {
    console.log('Received values of form: ', values);
    services
      .registerCode({
        ...values,
        userType: 'PATIENT',
      })
      .then(res => {
        console.log(res);
      });
  };
  const onLogin = async values => {
    console.log('Received values of form: ', values);
    const res = await vsf.services?.AccountController_staffLogin_ed24bb?.(values, {
      headers: {
        'Captcha-Key': captcha.captchaKey,
      }
    })
    console.log(res);
    if (res.code === 200) {
      vsf.showToast('登录成功')
      setUserInfo(res.data);
      setLoginModalOpen(false)
    }
  };

  //获取图形验证码
  const onLoginCode = values => {
    console.log('Received values of form: ', values);
    services
      .loginCode({})
      .then(res => {
        console.log(res);
        setCaptcha(res.data || {})
      });
  };
  const onFinish = values => {
    if (isLogin) {
      onLogin(values);
    } else {
      onRegister(values);
    }
  };
  const onCode = () => {
    const values = form.getFieldsValue();
    if (isLogin) {
      onLoginCode(values);
    } else {
      onRegisterCode(values);
    }
  };

  useEffect(() => {
    onLoginCode()
  }, [])
  if (!loginModalOpen) return null;
  return (
    <div className="login-container" onClick={() => setLoginModalOpen(false)}>
      <div className="login-block" onClick={(e) => e.stopPropagation()}>
        <Form
          form={form}
          name="normal_login"
          className="login-form"
          onFinish={onFinish}
        >
          <div className="title">欢迎登录</div>
          <Form.Item
            rules={[
              { required: true, message: '请输入手机号!' },
              {
                pattern: /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/,
                message: '请输入正确的手机号'
              }]}
            name="username">
            <Input
              allowClear
              size="large"
              // prefix={
              //   <MobileOutlined
              //     className="site-form-item-icon"
              //     style={{ color: '#1890ff' }}
              //   />
              // }
              placeholder="请输入手机号!"
            />
          </Form.Item>
          <Form.Item rules={[{ required: true, message: '请输入密码!' }]} name="password">
            <Input.Password
              size="large"
              // prefix={
              //   <LockOutlined
              //     className="site-form-item-icon"
              //     style={{ color: '#1890ff' }}
              //   />
              // }
              type="password"
              placeholder="请输入密码"
            />
          </Form.Item>
          <a className="login-form-forgot" style={{ float: 'right', marginTop: -18, zIndex: 99999, position: 'relative' }} onClick={() => { setLoginModalOpen(false); setSignModalOpen(true); setForgotPassword(true);; setUserCenter(false) }}>
              忘记密码 ？
            </a>
          <Row style={{ width: '100%'}}>
            <Col flex="2 1" style={{ margin: '0px 8px 0px 0px' }}>
              <Form.Item rules={[{ required: true, message: '请输入验证码!' }]} name="captcha">
                <Input
                  size="large"
                  // prefix={
                  //   <MailOutlined
                  //     className="site-form-item-icon"
                  //     style={{ color: '#1890ff' }}
                  //   />
                  // }
                  placeholder="请输入验证码!"
                ></Input>
              </Form.Item>
            </Col>
            <Col
              flex="1 2"
              style={{ float: 'right', marginBottom: '24px' }}
            >
              <Button
                size="large"
                onClick={() => {
                  onCode();
                }}
              >
                {captcha.captchaImg && <img style={{ width: '100%', height: '100%' }} src={captcha.captchaImg} alt="" />}
              </Button>
            </Col>
          </Row>
          {/* </Tabs> */}
          {isLogin && (
            <div>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>保持登录状态</Checkbox>
                </Form.Item>
                {/* <a className="login-form-forgot" style={{ float: 'right' }} onClick={() => { setLoginModalOpen(false); setSignModalOpen(true); setForgotPassword(true);; setUserCenter(false) }}>
                  忘记密码 ？
                </a> */}
              </Form.Item>
            </div>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: '100%' }}
              size="large"
            >
              {isLogin ? '提交' : '注册'}
            </Button>
          </Form.Item>
        </Form>
        <div className="other-way-login">
          <div>
            没有账户？<Button type="link" onClick={() => { setLoginModalOpen(false); setSignModalOpen(true); setForgotPassword(false); setUserCenter(false) }}>注册账户</Button>
          </div>
          {/* <div>
              <a
                className="login-form-register"
                onClick={() => {
                  setIsLogin(!isLogin);
                }}
              >
                {isLogin ? '注册' : '返回登录'}
              </a>
            </div> */}
        </div>
      </div>
    </div>
  );
};


export default definePage(Login);
