import vp2033301 from './vp2033301';
import vp2033401 from './vp2033401';
import vp2033402 from './vp2033402';
import vp2033404 from './vp2033404';
import vp2033501 from './vp2033501';
import vp2033601 from './vp2033601';
import vp2033701 from './vp2033701';
import vp2033702 from './vp2033702';
import vp2033703 from './vp2033703';
import vp2033704 from './vp2033704';
import vp2033705 from './vp2033705';
import vp2033706 from './vp2033706';
import vp2033707 from './vp2033707';
import vp2034001 from './vp2034001';
import vp2034002 from './vp2034002';
import vp2034201 from './vp2034201';
import vp2034301 from './vp2034301';
import vp2034302 from './vp2034302';
import vp2034303 from './vp2034303';
import vp2034304 from './vp2034304';
import vp2034305 from './vp2034305';
import vp2034306 from './vp2034306';
import vp2034307 from './vp2034307';
import vp2034308 from './vp2034308';
import vp2034309 from './vp2034309';
import vp2034501 from './vp2034501';
import vp2034701 from './vp2034701';
import vp2034702 from './vp2034702';
import vp2035102 from './vp2035102';
import vp2035205 from './vp2035205';
import vp2035206 from './vp2035206';
import vp2035207 from './vp2035207';
import vp2035208 from './vp2035208';
import vp2035301 from './vp2035301';
import vp2035501 from './vp2035501';
import vp2035601 from './vp2035601';
import vp2035701 from './vp2035701';
import vp2035702 from './vp2035702';
import vp2035901 from './vp2035901';
import vp2035902 from './vp2035902';
import vp2036001 from './vp2036001';
import vp2036002 from './vp2036002';
import vp2036003 from './vp2036003';

export default {
  ...vp2033301,
  ...vp2033401,
  ...vp2033402,
  ...vp2033404,
  ...vp2033501,
  ...vp2033601,
  ...vp2033701,
  ...vp2033702,
  ...vp2033703,
  ...vp2033704,
  ...vp2033705,
  ...vp2033706,
  ...vp2033707,
  ...vp2034001,
  ...vp2034002,
  ...vp2034201,
  ...vp2034301,
  ...vp2034302,
  ...vp2034303,
  ...vp2034304,
  ...vp2034305,
  ...vp2034306,
  ...vp2034307,
  ...vp2034308,
  ...vp2034309,
  ...vp2034501,
  ...vp2034701,
  ...vp2034702,
  ...vp2035102,
  ...vp2035205,
  ...vp2035206,
  ...vp2035207,
  ...vp2035208,
  ...vp2035301,
  ...vp2035501,
  ...vp2035601,
  ...vp2035701,
  ...vp2035702,
  ...vp2035901,
  ...vp2035902,
  ...vp2036001,
  ...vp2036002,
  ...vp2036003,
};
