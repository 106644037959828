import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserAccountController_createUserInfo_d3bdc9: {
    method: 'post',
    url: '/api/user-account/create-user-info',
    parameterFomatter: (data?: { btoParam: CreateUserBto9D1Edc }) => data,
    responseFormatter: (_, __, data?: AccountDetailVo887414) => data,
  },
  UserPageController_getById_97f416: {
    method: 'post',
    url: '/api/user-account/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: AccountDetailVo887414) => data,
  },
});
