import { createServices } from '@vs/vsf-boot';
export default createServices({
  ProjectInfoController_getPagedByStatus_360fe1: {
    method: 'post',
    url: '/api/project-info/get-paged-by-status',
    parameterFomatter: (data?: { qto: ProjectInfoPageQto8C9313 }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ProjectInfoPageVo12Db7B[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
