import { createServices } from '@vs/vsf-boot';
export default createServices({
  AccountController_LoginEnd_6e568e: {
    method: 'post',
    url: '/api/user-account/login-end',
    parameterFomatter: (data?: {
      username: string;
      password: string;
      captcha: string;
    }) => data,
    responseFormatter: (_, __, data?: CreateVo887414) => data,
  },
});
