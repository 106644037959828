import { createServices } from '@vs/vsf-boot';
export default createServices({
  SuggestionController_getPagedBySuggestInfoPageQto_561ee1: {
    method: 'post',
    url: '/api/suggestion-info/get-paged-by-suggest-info-page-qto',
    parameterFomatter: (data?: { qto: SuggestInfoPageQto250C40 }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: SuggestionPageVo0E1C65[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
