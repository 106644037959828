import './index.less';

import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import vsf, { definePage, renderRoutes, RouteConfig } from '@vs/vsf-boot';
import React, { useEffect, useState } from 'react';
import { Dropdown, Space, Typography } from '@vs/vsf-kit';
const routerList = [
  {
    label: '首页',
    path: '/index',
  },
  {
    label: '关于我们',
    path: '/about',
  },
  {
    label: '意见反馈',
    path: '/feedback',
  },
];

const Header = (props) => {
  const { global } = props?.stores || {};
  const {
    directoryList,
    setCurrentDirectory,
    getDirectoryList,
    currentDirectory,
    setLoginModalOpen,
    setSignModalOpen,
    setForgotPassword,
    setUserData,
    setUserCenter,
    userInfo,
    setUserInfo,
  } = global || {};

  const [options, setOptions] = useState([]);
  const items = [
    {
      key: '1',
      label: (
        <div
          onClick={() => {
            setSignModalOpen(true);
            setForgotPassword(false);
            setUserCenter(true);
          }}
        >
          个人中心
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          onClick={() => {
            setUserData(null);
            setUserInfo({});
          }}
        >
          退出
        </div>
      ),
    },
  ];
  const handleClick = (path) => () => {
    if (path === '/feedback' && !userInfo?.userId) {
      setLoginModalOpen(true);
      return vsf.showToast('登录后才能进行意见反馈');
    }
    vsf?.navigateTo(path);
  };
  useEffect(() => {
    getDirectoryList();
  }, [getDirectoryList]);

  useEffect(() => {
    setOptions(
      directoryList?.map((item) => ({
        ...item,
        secondDirectory: item.firstDirectory,
      })),
    );
  }, [directoryList]);
  return (
    <div className="header">
      <div className="main-box header-content">
        <div className="left">
          {props.history.location.pathname.indexOf('/directory') === 0 ? (
            <div className="dropdown">
              <Dropdown
                menu={{
                  items: options?.map((item) => ({
                    key: item.id,
                    label: item.firstDirectory,
                  })),
                  selectable: true,
                  defaultSelectedKeys: [String(currentDirectory.id)],
                  onClick: (item, key) => {
                    console.log(item);
                    setCurrentDirectory(
                      options?.find((el) => el.id == item.key),
                    );
                    if (
                      props.history.location.pathname.indexOf(
                        '/directory/detail',
                      ) === 0
                    ) {
                      vsf.navigateTo(`/directory?fistId=${item.key}`);
                    }
                  },
                }}
              >
                <Typography.Link>
                  <Space>
                    {currentDirectory.firstDirectory}
                    <DownOutlined />
                  </Space>
                </Typography.Link>
              </Dropdown>
            </div>
          ) : (
            <div className="title">
              <img src="logo.png" alt="" />
              <span>AI for Healthcare</span>
            </div>
          )}
        </div>
        <div className="right">
          <div className="router">
            {routerList?.map((item, index) => {
              return (
                <div
                  onClick={handleClick(item.path)}
                  key={item.path}
                  className={
                    item.path === props.history.location.pathname
                      ? 'active'
                      : 'nav'
                  }
                >
                  {item.label}
                </div>
              );
            })}
          </div>
          {/* <div className="avatar">
          <Image src={DoctorAvatar} preview={false} />
        </div> */}
          {!userInfo?.name ? (
            <div className="not-login">
              <div className="nav" onClick={() => setLoginModalOpen(true)}>
                登录
              </div>
              |
              <div
                className="nav"
                onClick={() => {
                  setSignModalOpen(true);
                  setForgotPassword(false);
                  setUserCenter(false);
                }}
              >
                注册
              </div>
            </div>
          ) : (
            <div className="role">
              <Dropdown menu={{ items }}>
                <div className="role dropdown-container">
                  {userInfo?.name}
                  <DownOutlined style={{ fontSize: 16 }} />
                </div>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default definePage(Header);
