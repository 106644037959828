import vsf, { definePage } from '@vs/vsf-boot';
import {
    Section,
    VSFormItem,
    VSFormFooter,
    VSFormFooterSubmit,
    VSFormFooterReset,
    VSForm,
    VSFormLayout,
    Button,
    Tooltip,
} from '@vs/vsf-kit';
import './index.less'
import React, { useState, useEffect } from 'react';
import {
    EditOutlined,
} from '@ant-design/icons';

let timeChange;
const CreateUser = (props) => {
    const { global } = props?.stores || {};
    const {
        setSignModalOpen,
        setUserCenter,
        signModalOpen,
        forgotPassword,
        setForgotPassword,
        userCenter,
        setUserInfo,
        setUserData,
        userInfo,
        setLoginModalOpen
    } = global || {};
    // const [form] = VSForm.useForm();
    const [ischangePhone, setIschangePhone] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const [time, setTime] = useState(60);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnContent, setBtnContent] = useState('获取验证码');
    const [currentUserName, setCurrentUserName] = useState('');
    const updatePassword = async (params) => {
        const res = await vsf.services?.AccountController_updatePasswordToLogin_b2ca8f?.(
            {
                btoParamE: {
                    id: props.routes?.query.id,
                    "userType": "USER",
                    loginMethodBtoList: [
                        {
                            id: props.routes?.query.id,
                            iLoginMethodBase: {
                                "@class": "com.vs.user_account.service.bto.UpdateUserBto$LoginMethodPasswordBto",
                                userName: params.phone,
                                password: params.password,
                                userId: props.routes?.query.id,
                            }
                        }
                    ],
                    identificationBto: {
                        ...params.identificationDto,
                        "userType": "USER",
                        "unit": params.unit,
                        "name": params.name,
                        "position": params.position,
                        "email": params.email,
                        phone: params.phone
                    }
                },
                captcha: params.captcha,
            }
        )
        if (res.code == 200) {
            setUserInfo(res.data.identificationDto)
            vsf.showToast('密码修改成功')
            closeModal()
        }
    }
    const updateUserFront = async (params) => {
        const res = await vsf.services?.AccountController_updateUserFront_366247?.(
            {
                btoParam: {
                    id: props.routes?.query.id,
                    "userType": "USER",
                    loginMethodBtoList: [
                        {
                            id: props.routes?.query.id,
                            iLoginMethodBase: {
                                "@class": "com.vs.user_account.service.bto.UpdateUserBto$LoginMethodPasswordBto",
                                userName: params.phone,
                                password: params.password,
                                userId: props.routes?.query.id,
                            }
                        }
                    ],
                    identificationBto: {
                        ...params.identificationDto,
                        "userType": "USER",
                        "unit": params.unit,
                        "name": params.name,
                        "position": params.position,
                        "email": params.email,
                        phone: params.phone
                    }
                },
                captcha: params.captcha,
            }
        )
        if (res.code === 200) {
            vsf.showToast('修改成功')
            closeModal()
        }
    }
    const registerUSer = async (params) => {
        console.log(params);
        // Tips: 函数入参为表单数据，格式和RO一致
        // Tips: 以下是你选择的请求函数
        const res =
            await vsf.services?.UserAccountController_createUserInfo_d3bdc9?.({
                // TODO: 请检查以下这部分传参是否符合需求
                btoParam: {
                    loginMethodBtoList: [
                        {
                            iLoginMethodBase: {
                                "@class": "com.vs.user_account.service.bto.CreateUserBto$LoginMethodPasswordBto",
                                userName: params.phone,
                                password: params.password,
                            }
                        }
                    ],
                    identificationBto: {
                        "userType": "USER",
                        "unit": params.unit,
                        "name": params.name,
                        "position": params.position,
                        "email": params.email,
                        phone: params.phone
                    },
                },
                extApiParam: params?.extApiParam,
                captcha: params.captcha,
            }); // Tips: 需要返回提交的完整数据，和表单RO一致
        if (res.code == 200) {
            vsf.showToast('注册成功')
            setUserInfo(res.data)
            closeModal()
        }
        return params;
    }

    const reqUserInfo = async () => {
        const res =
            await vsf.services?.UserPageController_getById_97f416?.({
                // await vsf.services?.UserController_getPagedByUserPageQto_f6c9c6?.({
                // TODO: 请检查以下这部分传参是否符合需求
                id: userInfo?.userId
            }); // Tips: 需要返回内容数据，结构和表单RO一致

        setCurrentUserName(res.data?.identificationDto?.phone)
        vsf.refs.VSForm_sign1.setFieldsValue({ ...res.data, ...res?.data?.identificationDto, phone: ischangePhone || res.data?.identificationDto?.phone })
    }
    useEffect(() => {
        clearInterval(timeChange);
        return () => clearInterval(timeChange);
    }, []);

    useEffect(() => {
        if (time > 0 && time < 60) {
            setBtnContent(`${time}s后重发`);
        } else {
            clearInterval(timeChange);
            setBtnDisabled(false);
            setTime(60);
            setBtnContent('获取验证码');
        }
    }, [time]);
    useEffect(() => {
        userCenter && reqUserInfo()
    }, [])
    // 点击验证码按钮
    const getPhoneCaptcha = () => {
        vsf.refs.VSForm_sign1.validateFieldsReturnFormatValue(['phone']).then(async values => {
            console.log(values);
            setBtnDisabled(true);
            const res =
                await vsf.services?.AccountController_getCaptcha_64bee8?.({
                    cellPhone: values.phone
                }); // Tips: 需要返回内容数据，结构和表单RO一致
            if (res.code === 200) {
                timeChange = setInterval(() => setTime(t => --t), 1000);
            } else {
                setBtnDisabled(false);
            }
        }).catch(err => console.error(err));
    };
    const closeModal = () => {
        setSignModalOpen(false);
        setForgotPassword(false);
        setUserCenter(false);
    }
    if (!signModalOpen) return null;
    return (
        <div className="sign-container" onClick={closeModal}>
            <div className="sign-block" onClick={(e) => e.stopPropagation()}>
                <div className='sign-title'>{userCenter ? '个人信息' : forgotPassword ? '修改密码' : '注册'}</div>
                <VSForm
                    labelCol={{ span: 7 }}
                    // wrapperCol={{ span: 10 }}
                    readonly={userCenter && !isEdit}
                    className="sign-form"
                    vsid="14719"
                    id="VSForm_sign1"
                    onValuesChange={(changedValues) => {
                        console.log('changedValues==', changedValues, currentUserName, ischangePhone);
                        if (ischangePhone && changedValues.phone == currentUserName) {
                            setIschangePhone(null)
                        }
                        if (!ischangePhone && changedValues.userName !== currentUserName) {
                            setIschangePhone(changedValues.phone)
                        }
                    }}
                    onSubmit={forgotPassword ? updatePassword : userCenter ? updateUserFront : registerUSer}
                >
                    <VSFormLayout key="0" columnCount={1}></VSFormLayout>
                    {
                        !forgotPassword ? <>
                            {!isEdit && userCenter && <Tooltip title="点击修改个人信息，修改联系方式需短信验证"><EditOutlined style={{ float: 'right', fontSize: 18, marginTop: 8, marginRight: 10 }} onClick={() => setIsEdit(true)} /></Tooltip>}
                            <VSFormItem
                                name={['name']}
                                label="用户姓名"
                                required={true}
                                valueType="text"
                            ></VSFormItem>
                            <VSFormItem
                                name={['password']}
                                label="密码"
                                valueType="password"
                                rules={[
                                    { min: 6, message: '密码不能小于六位' }
                                ]}
                                required={true}
                            ></VSFormItem>
                            <VSFormItem
                                name={['unit']}
                                label="单位名称"
                                required={true}
                                valueType="text"
                            ></VSFormItem>
                            <VSFormItem name={['position']} label="职位" required={true} valueType="text"></VSFormItem>
                            <VSFormItem name={['email']} label="邮箱" required={false} valueType="text"></VSFormItem></> : null
                    }
                    <VSFormItem
                        name={['phone']}
                        label="联系方式"
                        required={true}
                        valueType="text"
                        rules={[
                            { required: true, message: '请输入手机号!' },
                            {
                                pattern: /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/,
                                message: '请输入正确的手机号'
                            }]}
                    ></VSFormItem>
                    {
                        forgotPassword && <VSFormItem
                            name={['password']}
                            label="密码"
                            valueType="password"
                            rules={[
                                { min: 6, message: '密码不能小于六位' }
                            ]}
                            required={true}
                        ></VSFormItem>
                    }
                    <VSFormLayout style={{ paddingLeft: 111 }} gutter={[24]} labelCol={{ span: 18 }} key="1" columnCount={2}>
                        <VSFormItem
                            rules={[{ required: !(userCenter && !ischangePhone), message: '请输入验证码!' }]}
                            style={{ width: 145 }}
                            hidden={userCenter && !ischangePhone}
                            name={['captcha']} />
                        {!(userCenter && !ischangePhone) &&
                            <Button
                                disabled={btnDisabled}
                                onClick={getPhoneCaptcha}
                                style={{ marginLeft: 21 }}
                            >
                                {btnContent}
                            </Button>}
                    </VSFormLayout>
                    {!userCenter ? <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        style={{ width: '100%' }}
                        size="large"
                    >
                        提交
                    </Button> : isEdit ? <div className='sign-form-button'>
                        <Button
                            type="primary"
                            htmlType="submit"
                        // className="login-form-button"
                        // style={{ width: '100%' }}
                        // size="large"
                        >
                            提交
                        </Button>
                        <Button
                            type="primary"
                            // className="login-form-button"
                            // style={{ width: '100%' }}
                            // size="large"
                            onClick={() => { setIsEdit(false); setIschangePhone(null) }}
                        >
                            取消
                        </Button>
                    </div> : null}
                    {/* <Button
                        type="secondary"
                        className="login-form-button"
                        style={{ width: '100%', marginTop: 24 }}
                        size="large"
                        onClick={() => setSignModalOpen(false)}
                    >
                        取消
                    </Button> */}
                </VSForm>
            </div>
        </div>
    );
};
export default definePage(CreateUser);
