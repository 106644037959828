import { createServices } from '@vs/vsf-boot';
export default createServices({
  CategoryController_deleteFirstCategory_bae870: {
    method: 'post',
    url: '/api/category-info/delete-first-category',
    parameterFomatter: (data?: { btoParam: DeleteFirstCategoryBto9D9F0C }) =>
      data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
